/* ========== */
/* = Layout = */
/* ========== */

/* natural box layout model */
*,
input[type='search'] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/*  Base  */
@one-unit-space: 20rem/16;

/* Ensure body stretches the full height of the page */
html {
    height: 100%;
}
body {
    min-height: 100%;
}
#page {
    min-height: 100vh;
}
body.show-modal {
    overflow: hidden;
}

body {
    margin: 0 auto;
}
ul {
    padding-left: 0;
}
ul {
    list-style-type: none;
}
dl dd {
    margin-left: 0;
}
img {
    vertical-align: middle;
}

hr {
    clear: both;
}

// lazysizes auto width calculation
picture img[data-sizes='auto'] {
    display: block;
    width: 100%;
}

// z-indices

.layer-base {
    z-index: 00;
}
.layer-one {
    z-index: 100;
}
.layer-two {
    z-index: 200;
}
.layer-two5 {
    z-index: 250;
}
.layer-three {
    z-index: 300;
}
.layer-three5 {
    z-index: 350;
}
.layer-four {
    z-index: 400;
}
.xs-plus({
    .layer-base-xs  { z-index:  00; }
    .layer-one-xs   { z-index: 100; }
    .layer-two-xs   { z-index: 200; }
    .layer-two5-xs  { z-index: 250; }
    .layer-three-xs { z-index: 300; }
    .layer-four-xs  { z-index: 400; }
});

// Helper Classes
.contain-width {
    video,
    svg,
    img {
        max-width: 100%;
        height: auto;
    }
}
.contain-height {
    video,
    svg,
    img {
        max-height: 100%;
        width: auto;
    }
}
.stretch-object {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fill-object {
    position: relative;
    video,
    svg,
    img {
        position: absolute;
        width: 100%;
        height: auto;
        @supports (object-fit: cover) {
            height: 100%;
            object-fit: cover;
        }
    }
}
.fill-contain {
    video,
    svg,
    img {
        @supports (object-fit: contain) {
            object-fit: contain;
        }
    }
}
.fill-top {
    object-position: center top;
}
.video-container,
.embed-fluid {
    position: relative;
    overflow: hidden;
    height: 0;
    video,
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
.embed-fluid,
.aspect-16-9 {
    padding-bottom: percentage(9/16) !important;
}

.truncate {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.recurse-breakpoints-all({
    .occupy@{suffix}        { display: block; }
    .inline-block@{suffix}  { display: inline-block; }
    .clearboth@{suffix}     { clear: both; }
    .nooverflow@{suffix}    { overflow: hidden; }
    .resetcoords@{suffix}   { position: relative; }
    .freeflow@{suffix}      { position: absolute; }
    .inline@{suffix}        { display: inline; }
});
.has-js .js-hidden,
.js-nonjs-hidden,
.has-no-js .nonjs-hidden {
    display: none;
}
.has-no-js .nonjs-visible {
    display: block !important;
}
.xxs-plus({
    .inline-block-xxs { display: inline-block; }
});
.sm-plus({
    .occupy-sm { display: block; }
});
.pointer-events-none {
    pointer-events: none;
}
.pointer-events-auto {
    pointer-events: auto;
}

.hide-xxs-upto {
    .xxxs-upto({display: none !important; });
}
.hide-xs-upto {
    .xxs-upto({ display: none !important; });
}
.hide-sm-upto {
    .xs-upto({ display: none !important; });
}
.hide-md-upto {
    .sm-upto({ display: none !important; });
}
.hide-lg-upto {
    .md-upto({ display: none !important; });
}
.hide-xs-plus {
    .xs-plus({ display: none !important; });
}
.hide-sm-plus {
    .sm-plus({ display: none !important; });
}
.hide-md-plus {
    .md-plus({ display: none !important; });
}
.hide-lg-plus {
    .lg-plus({ display: none !important; });
}

// Alignments
.aligner {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
.valigner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.align-top {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}
.align-bottom {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
}
.align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.recurse-breakpoints({
    .f-1@{suffix} { order: 1; }
    .f-2@{suffix} { order: 2; }
    .f-3@{suffix} { order: 3; }
});
.recurse-breakpoints-all({
    .flushleft@{suffix}   { float: left; }
    .flushright@{suffix}  { float: right; }
    .noflush@{suffix}     { float: none; }
});
.max-half {
    max-width: 50%;
}

.container-values() {
    @contentpads: 6.25%, 7%, 3%, 3%, 0.03 * @max-container;
    @gutterpads: 6.25%, 7%, 3%, 3%, 0.03 * @max-container;
    @breakpoints: '', @xxs-min, @xs-min, @sm-min, @xl-min;
}
@max-container: 16px * @xl-min / 1em;
#page {
    margin: 0 auto;
    position: relative;
    .layer-one;
}
.container {
    .container-values();
    .recurse-rule-breakpoints({
        padding-left: @value;
        padding-right:@value;
        padding-left: @value*1vw/1%;
        padding-right:@value*1vw/1%;
    }, @contentpads, @breakpoints);
}
.container-gutters {
    .container-values();
    .recurse-rule-breakpoints({
        padding-left: @value;
        padding-right:@value;
        padding-left: @value*1vw/1%;
        padding-right:@value*1vw/1%;
    }, @gutterpads, @breakpoints);
}

.container-xs {
    .container-values();
    .recurse-rule-breakpoints({
        padding-left: @value;
        padding-right:@value;
        padding-left: @value*1vw/1%;
        padding-right:@value*1vw/1%;
    }, @contentpads, @breakpoints, @xs-min);
}
.container-gutters-xs {
    .container-values();
    .recurse-rule-breakpoints({
        padding-left: @value;
        padding-right:@value;
        padding-left: @value*1vw/1%;
        padding-right:@value*1vw/1%;
    }, @gutterpads, @breakpoints, @xs-min);
}
.container-gutters-sm {
    .container-values();
    .recurse-rule-breakpoints({
        padding-left: @value;
        padding-right:@value;
        padding-left: @value*1vw/1%;
        padding-right:@value*1vw/1%;
    }, @gutterpads, @breakpoints, @sm-min);
}

.container .bleed {
    .container-values();
    .recurse-rule-breakpoints({
        margin-left: -@value*100/(100-(2*@value));
        margin-right:-@value*100/(100-(2*@value));
        margin-left: -@value*1vw/1%;
        margin-right:-@value*1vw/1%;
    }, @contentpads, @breakpoints);
}
.container .bleed-xs {
    .container-values();
    .recurse-rule-breakpoints({
        margin-left: -@value*100/(100-(2*@value));
        margin-right:-@value*100/(100-(2*@value));
        margin-left: -@value*1vw/1%;
        margin-right:-@value*1vw/1%;
    }, @contentpads, @breakpoints, @xs-min);
}
.container-xs .bleed {
    .container-values();
    .recurse-rule-breakpoints({
        margin-left: -@value*100/(100-(2*@value));
        margin-right:-@value*100/(100-(2*@value));
        margin-left: -@value*1vw/1%;
        margin-right:-@value*1vw/1%;
    }, @contentpads, @breakpoints, @xs-min);
}
.container .bleed-until-xs {
    .container-values();
    .recurse-rule-breakpoints({
        margin-left: -@value*100/(100-(2*@value));
        margin-right:-@value*100/(100-(2*@value));
    }, @contentpads, @breakpoints, 0, @xs-min);
    .xs-plus({
        margin-left: 0;
        margin-right:0;
    });
}

.xl-plus({
    .wrapper-max,
    .wrapper-max-left   { padding-left: calc(~'(100vw - @{max-container})/2') !important; }
    .wrapper-max,
    .wrapper-max-right  { padding-right:calc(~'(100vw - @{max-container})/2') !important; }
    
    .container-max,
    .container-max-left { padding-left: calc(~'(100vw - @{max-container})/2 + @{max-container}*.03') !important; }
    .container-max,
    .container-max-right{ padding-right:calc(~'(100vw - @{max-container})/2 + @{max-container}*.03') !important; }
});

// Grid
.full-width {
    width: 100%;
}
.full-height {
    height: 100%;
}
.edge-to-edge {
    .full-width;
    .full-height;
    top: 0;
    left: 0;
}
.flex {
    display: flex;
}
.lg-plus({
   .flex-lg { .flex; } 
});
.full-flex {
    flex: 1;
}
.list-grid {
    display: flex;
    flex-flow: row wrap;
    .has-no-flexbox & {
        display: block;
    }
    margin: -0.5 * @one-unit-space 0 0 -0.5 * @one-unit-space;

    > li {
        flex: 1;
        display: flex;
        flex-basis: 100%;
        .has-no-flexbox & {
            float: left;
            display: block;
        }
        padding: 0.5 * @one-unit-space 0 0 0.5 * @one-unit-space;
        min-width: 0;
        > * {
            width: 100%;
        }
    }
    &-noexpand > li {
        flex-grow: 0;
    }

    .recurse-breakpoints({
        &.col-5@{suffix} > li {
                            flex-basis: percentage(1/5);
            .has-no-flexbox & { width:  percentage(1/5); }
        }
        &.col-4@{suffix} > li {
                            flex-basis: percentage(1/4);
            .has-no-flexbox & { width:  percentage(1/4); }
        }
        li.span-third@{suffix},
        &.col-3@{suffix} > li {
                            flex-basis: percentage(1/3);
            .has-no-flexbox & { width:  percentage(1/3); }
        }
        li.span-half@{suffix},
        &.col-2@{suffix} > li {
                            flex-basis: percentage(1/2);
            .has-no-flexbox & { width:  percentage(1/2); }
        }
        li.span-full@{suffix},
        &.col-1@{suffix} > li {
                            flex-basis: percentage(1);
            .has-no-flexbox & { width:  percentage(1); }
        }
    });

    .xs-plus({
        margin: -1*@one-unit-space 0 0 -1*@one-unit-space;
        > li { padding: 1*@one-unit-space 0 0 1*@one-unit-space; }
        &-condensed {
            margin: -0.5*@one-unit-space 0 0 -0.5*@one-unit-space;
            > li { padding: 0.5*@one-unit-space 0 0 0.5*@one-unit-space; }
        }
    });
}

// Heights
.aspect-box {
    &:before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
    }
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
.recurse-breakpoints({
    .ratio-9-16@{suffix}:before { padding-top: percentage(16/9); }
    .ratio-2-3@{suffix}:before  { padding-top: percentage(3/2); }
    .ratio-3-4@{suffix}:before  { padding-top: percentage(4/3); }
    .ratio-16-18@{suffix}:before{ padding-top: percentage(18/16); }
    .ratio-1-1@{suffix}:before  { padding-top: percentage(1); }
    .ratio-3-2@{suffix}:before  { padding-top: percentage(2/3); }
    .ratio-7-5@{suffix}:before  { padding-top: percentage(5/7); }
    .ratio-16-9@{suffix}:before { padding-top: percentage(9/16); }
    .ratio-16-8@{suffix}:before { padding-top: percentage(8/16); }
    .ratio-16-7@{suffix}:before { padding-top: percentage(7/16); }
    .ratio-16-5@{suffix}:before { padding-top: percentage(5/16); }
});

// Sticky
.sticky {
    position: sticky;
    top: 0;
    .xs-plus({
        top: @one-unit-space;
    });
    &-top {
        .xs-plus({ top: 0; });
    }
}

// Spacers
.nspace-four-top,
.nspace-four {
    margin-top: -4 * @one-unit-space !important;
}
.nspace-four-bottom,
.nspace-four {
    margin-bottom: -4 * @one-unit-space !important;
}
.nspace-three-top,
.nspace-three {
    margin-top: -3 * @one-unit-space !important;
}
.nspace-three-bottom,
.nspace-three {
    margin-bottom: -3 * @one-unit-space !important;
}
.nspace-two-top,
.nspace-two {
    margin-top: -2 * @one-unit-space !important;
}
.nspace-two-bottom,
.nspace-two {
    margin-bottom: -2 * @one-unit-space !important;
}
.nspace-line-top,
.nspace-line {
    margin-top: -1.5 * @one-unit-space !important;
}
.nspace-line-bottom,
.nspace-line {
    margin-bottom: -1.5 * @one-unit-space !important;
}
.nspace-one-top,
.nspace-one {
    margin-top: -1 * @one-unit-space !important;
}
.nspace-one-bottom,
.nspace-one {
    margin-bottom: -1 * @one-unit-space !important;
}
.nspace-3q-top,
.nspace-3q {
    margin-top: -0.75 * @one-unit-space!important;
}
.nspace-3q-bottom,
.nspace-3q {
    margin-bottom: -0.75 * @one-unit-space!important;
}
.nspace-half-top,
.nspace-half {
    margin-top: -0.5 * @one-unit-space !important;
}
.nspace-half-bottom,
.nspace-half {
    margin-bottom: -0.5 * @one-unit-space !important;
}
.nspace-1q-top,
.nspace-1q {
    margin-top: -0.25 * @one-unit-space!important;
}
.nspace-1q-bottom,
.nspace-1q {
    margin-bottom: -0.25 * @one-unit-space!important;
}
.nonspace-top,
.nonspace {
    margin-top: 0 !important;
}
.nonspace-bottom,
.nonspace {
    margin-bottom: 0 !important;
}

.space-six-top,
.space-six {
    margin-top: 6 * @one-unit-space !important;
}
.space-six-bottom,
.space-six {
    margin-bottom: 6 * @one-unit-space !important;
}
.space-four-top,
.space-four {
    margin-top: 4 * @one-unit-space !important;
}
.space-four-bottom,
.space-four {
    margin-bottom: 4 * @one-unit-space !important;
}
.space-three-top,
.space-three {
    margin-top: 3 * @one-unit-space !important;
}
.space-three-bottom,
.space-three {
    margin-bottom: 3 * @one-unit-space !important;
}
.space-two-top,
.space-two {
    margin-top: 2 * @one-unit-space !important;
}
.space-two-bottom,
.space-two {
    margin-bottom: 2 * @one-unit-space !important;
}
.space-line-top,
.space-line {
    margin-top: 1.5 * @one-unit-space !important;
}
.space-line-bottom,
.space-line {
    margin-bottom: 1.5 * @one-unit-space !important;
}
.space-one-top,
.space-one {
    margin-top: 1 * @one-unit-space !important;
}
.space-one-bottom,
.space-one {
    margin-bottom: 1 * @one-unit-space !important;
}
.space-3q-top,
.space-3q {
    margin-top: 0.75 * @one-unit-space !important;
}
.space-3q-bottom,
.space-3q {
    margin-bottom: 0.75 * @one-unit-space !important;
}
.space-half-top,
.space-half {
    margin-top: 0.5 * @one-unit-space !important;
}
.space-half-bottom,
.space-half {
    margin-bottom: 0.5 * @one-unit-space !important;
}
.space-1q-top,
.space-1q {
    margin-top: 0.25 * @one-unit-space !important;
}
.space-1q-bottom,
.space-1q {
    margin-bottom: 0.25 * @one-unit-space !important;
}
.space-1e-top,
.space-1e {
    margin-top: 0.125 * @one-unit-space !important;
}
.space-1e-bottom,
.space-1e {
    margin-bottom: 0.125 * @one-unit-space !important;
}
.nospace-top,
.nospace {
    margin-top: 0 !important;
}
.nospace-bottom,
.nospace {
    margin-bottom: 0 !important;
}
.xxs-plus({
    .nospace-top-xxs,       .nospace-xxs    { margin-top:   0 !important; }
    .nospace-bottom-xxs,    .nospace-xxs    { margin-bottom:0 !important; }
});
.xs-plus({
    .nspace-three-top-xs,   .nspace-three-xs{ margin-top:    -3*@one-unit-space !important; }
    .nspace-three-bottom-xs,.nspace-three-xs{ margin-bottom: -3*@one-unit-space !important; }
    .nspace-two-top-xs,     .nspace-two-xs  { margin-top:    -2*@one-unit-space !important; }
    .nspace-two-bottom-xs,  .nspace-two-xs  { margin-bottom: -2*@one-unit-space !important; }
    .nspace-one-top-xs,     .nspace-one-xs  { margin-top:    -1*@one-unit-space !important; }
    .nspace-one-bottom-xs,  .nspace-one-xs  { margin-bottom: -1*@one-unit-space !important; }
    .nspace-3q-top-xs,      .nspace-3q-xs   { margin-top:   -0.75*@one-unit-space !important; }
    .nspace-3q-bottom-xs,   .nspace-3q-xs   { margin-bottom:-0.75*@one-unit-space !important; }
    
    .space-three-top-xs,    .space-three-xs { margin-top:   3*@one-unit-space !important; }
    .space-three-bottom-xs, .space-three-xs { margin-bottom:3*@one-unit-space !important; }
    .space-two-top-xs,      .space-two-xs   { margin-top:   2*@one-unit-space !important; }
    .space-two-bottom-xs,   .space-two-xs   { margin-bottom:2*@one-unit-space !important; }
    .space-line-top-xs,     .space-line-xs  { margin-top:   1.5*@one-unit-space !important; }
    .space-line-bottom-xs,  .space-line-xs  { margin-bottom:1.5*@one-unit-space !important; }
    .space-one-top-xs,      .space-one-xs   { margin-top:   1*@one-unit-space !important; }
    .space-one-bottom-xs,   .space-one-xs   { margin-bottom:1*@one-unit-space !important; }
    .space-3q-top-xs,       .space-3q-xs    { margin-top:   0.75*@one-unit-space !important; }
    .space-3q-bottom-xs,    .space-3q-xs    { margin-bottom:0.75*@one-unit-space !important; }
    .nospace-top-xs,        .nospace-xs     { margin-top:   0 !important; }
    .nospace-bottom-xs,     .nospace-xs     { margin-bottom:0 !important; }
});
.sm-plus({
    .nspace-two-top-sm,     .nspace-two-sm  { margin-top:    -2*@one-unit-space !important; }
    .nspace-two-bottom-sm,  .nspace-two-sm  { margin-bottom: -2*@one-unit-space !important; }
    .nspace-1px-top-sm,     .nspace-1px-sm  { margin-top:    -1px !important; }
    
    .space-six-top-sm,      .space-six-sm   { margin-top:   6*@one-unit-space !important; }
    .space-six-bottom-sm,   .space-six-sm   { margin-bottom:6*@one-unit-space !important; }
    .space-four-top-sm,     .space-four-sm  { margin-top:   4*@one-unit-space !important; }
    .space-four-bottom-sm,  .space-four-sm  { margin-bottom:4*@one-unit-space !important; }
    .space-three-top-sm,    .space-three-sm { margin-top:   3*@one-unit-space !important; }
    .space-three-bottom-sm, .space-three-sm { margin-bottom:3*@one-unit-space !important; }
    .space-two-top-sm,      .space-two-sm   { margin-top:   2*@one-unit-space !important; }
    .space-two-bottom-sm,   .space-two-sm   { margin-bottom:2*@one-unit-space !important; }
    .space-line-top-sm,     .space-line-sm  { margin-top:   1.5*@one-unit-space !important; }
    .space-line-bottom-sm,  .space-line-sm  { margin-bottom:1.5*@one-unit-space !important; }
    .space-one-top-sm,      .space-one-sm   { margin-top:   1*@one-unit-space !important; }
    .space-one-bottom-sm,   .space-one-sm   { margin-bottom:1*@one-unit-space !important; }
    .space-3q-top-sm,       .space-3q-sm    { margin-top:   0.75*@one-unit-space !important; }
    .space-3q-bottom-sm,    .space-3q-sm    { margin-bottom:0.75*@one-unit-space !important; }
    .space-half-top-sm,     .space-half-sm  { margin-top:   0.5*@one-unit-space !important; }
    .space-half-bottom-sm,  .space-half-sm  { margin-bottom:0.5*@one-unit-space !important; }
    .nospace-top-sm,        .nospace-sm     { margin-top:   0 !important; }
    .nospace-bottom-sm,     .nospace-sm     { margin-bottom:0 !important; }
});
.md-plus({
    .nspace-line-top-md,    .nspace-line-md { margin-top:    -1.5*@one-unit-space !important; }
    .nspace-line-bottom-md, .nspace-line-md { margin-bottom: -1.5*@one-unit-space !important; }
    
    .space-line-top-md,     .space-line-md  { margin-top:   1.5*@one-unit-space !important; }
    .space-line-bottom-md,  .space-line-md  { margin-bottom:1.5*@one-unit-space !important; }
    .nospace-top-md,        .nospace-md     { margin-top:   0 !important; }
    .nospace-bottom-md,     .nospace-md     { margin-bottom:0 !important; }
});
.lg-plus({
    .space-two-top-lg,     .space-two-lg    { margin-top:    2*@one-unit-space !important; }
    .space-two-bottom-lg,  .space-two-lg    { margin-bottom: 2*@one-unit-space !important; }
});

.push-section-top,
.push-section {
    padding-top: 2 * @one-unit-space !important;
}
.push-section-bottom,
.push-section {
    padding-bottom: 2 * @one-unit-space !important;
}
.push-four-top,
.push-four {
    padding-top: 4 * @one-unit-space !important;
}
.push-four-bottom,
.push-four {
    padding-bottom: 4 * @one-unit-space !important;
}
.push-three-top,
.push-three {
    padding-top: 3 * @one-unit-space !important;
}
.push-three-bottom,
.push-three {
    padding-bottom: 3 * @one-unit-space !important;
}
.push-two-top,
.push-two {
    padding-top: 2 * @one-unit-space !important;
}
.push-two-bottom,
.push-two {
    padding-bottom: 2 * @one-unit-space !important;
}
.push-line-top,
.push-line {
    padding-top: 1.5 * @one-unit-space!important;
}
.push-line-bottom,
.push-line {
    padding-bottom: 1.5 * @one-unit-space!important;
}
.push-one-top,
.push-one {
    padding-top: 1 * @one-unit-space !important;
}
.push-one-bottom,
.push-one {
    padding-bottom: 1 * @one-unit-space !important;
}
.push-3q-top,
.push-3q {
    padding-top: 0.75 * @one-unit-space!important;
}
.push-3q-bottom,
.push-3q {
    padding-bottom: 0.75 * @one-unit-space!important;
}
.push-half-top,
.push-half {
    padding-top: 0.5 * @one-unit-space!important;
}
.push-half-bottom,
.push-half {
    padding-bottom: 0.5 * @one-unit-space!important;
}
.push-1q-top,
.push-1q {
    padding-top: 0.25 * @one-unit-space!important;
}
.push-1q-bottom,
.push-1q {
    padding-bottom: 0.25 * @one-unit-space!important;
}
.push-1e-top,
.push-1e {
    padding-top: 0.125 * @one-unit-space!important;
}
.push-1e-bottom,
.push-1e {
    padding-bottom: 0.125 * @one-unit-space!important;
}
.nopush-top,
.nopush {
    padding-top: 0 !important;
}
.nopush-bottom,
.nopush {
    padding-bottom: 0 !important;
}
.xxs-plus({
    .push-one-top-xxs,      .push-one-xxs   { padding-top:   1*@one-unit-space!important; }
    .push-one-bottom-xxs,   .push-one-xxs   { padding-bottom:1*@one-unit-space!important; }
    .push-half-top-xxs,     .push-half-xxs  { padding-top:   0.5*@one-unit-space!important; }
    .push-half-bottom-xxs,  .push-half-xxs  { padding-bottom:0.5*@one-unit-space!important; }
});
.xs-plus({
    .push-three-top-xs,     .push-three-xs  { padding-top:   3*@one-unit-space !important; }
    .push-three-bottom-xs,  .push-three-xs  { padding-bottom:3*@one-unit-space !important; }
    .push-two-top-xs,       .push-two-xs    { padding-top:   2*@one-unit-space !important; }
    .push-two-bottom-xs,    .push-two-xs    { padding-bottom:2*@one-unit-space !important; }
    .push-line-top-xs,      .push-line-xs   { padding-top:   1.5*@one-unit-space!important; }
    .push-line-bottom-xs,   .push-line-xs   { padding-bottom:1.5*@one-unit-space!important; }
    .push-one-top-xs,       .push-one-xs    { padding-top:   1*@one-unit-space !important; }
    .push-one-bottom-xs,    .push-one-xs    { padding-bottom:1*@one-unit-space !important; }
    .push-3q-top-xs,        .push-3q-xs     { padding-top:   0.75*@one-unit-space!important; }
    .push-3q-bottom-xs,     .push-3q-xs     { padding-bottom:0.75*@one-unit-space!important; }
    .push-half-top-xs,      .push-half-xs   { padding-top:   0.5*@one-unit-space!important; }
    .push-half-bottom-xs,   .push-half-xs   { padding-bottom:0.5*@one-unit-space!important; }
    .nopush-top-xs,         .nopush-xs      { padding-top:   0 !important; }
    .nopush-bottom-xs,      .nopush-xs      { padding-bottom:0 !important; }
});
.sm-plus({
    .push-section-top,      .push-section   { padding-top:   2.5*@one-unit-space !important; }
    .push-section-bottom,   .push-section   { padding-bottom:2.5*@one-unit-space !important; }
    .push-four-top-sm,      .push-four-sm   { padding-top:   4*@one-unit-space !important; }
    .push-four-bottom-sm,   .push-four-sm   { padding-bottom:4*@one-unit-space !important; }
    .push-three-top-sm,     .push-three-sm  { padding-top:   3*@one-unit-space !important; }
    .push-three-bottom-sm,  .push-three-sm  { padding-bottom:3*@one-unit-space !important; }
    .push-two-top-sm,       .push-two-sm    { padding-top:   2*@one-unit-space !important; }
    .push-two-bottom-sm,    .push-two-sm    { padding-bottom:2*@one-unit-space !important; }
    .push-line-top-sm,      .push-line-sm   { padding-top:   1.5*@one-unit-space!important; }
    .push-line-bottom-sm,   .push-line-sm   { padding-bottom:1.5*@one-unit-space!important; }
    .push-one-top-sm,       .push-one-sm    { padding-top:   1*@one-unit-space !important; }
    .push-one-bottom-sm,    .push-one-sm    { padding-bottom:1*@one-unit-space !important; }
    .push-half-top-sm,      .push-half-sm   { padding-top:   0.5*@one-unit-space!important; }
    .push-half-bottom-sm,   .push-half-sm   { padding-bottom:0.5*@one-unit-space!important; }
    .push-1q-top-sm,        .push-1q-sm     { padding-top:   0.25*@one-unit-space!important; }
    .push-1q-bottom-sm,     .push-1q-sm     { padding-bottom:0.25*@one-unit-space!important; }
    .nopush-top-sm,         .nopush-sm      { padding-top:   0 !important; }
    .nopush-bottom-sm,      .nopush-sm      { padding-bottom:0 !important; }
});
.lg-plus({
    .push-four-top-lg,      .push-four-lg   { padding-top:   4*@one-unit-space !important; }
    .push-four-bottom-lg,   .push-four-lg   { padding-bottom:4*@one-unit-space !important; }
    .push-two-top-lg,       .push-two-lg    { padding-top:   2*@one-unit-space !important; }
    .push-two-bottom-lg,    .push-two-lg    { padding-bottom:2*@one-unit-space !important; }
});

.pad-two-left,
.pad-two {
    padding-left: 2 * @one-unit-space !important;
}
.pad-two-right,
.pad-two {
    padding-right: 2 * @one-unit-space !important;
}
.pad-line-left,
.pad-line {
    padding-left: 1.5 * @one-unit-space !important;
}
.pad-line-right,
.pad-line {
    padding-right: 1.5 * @one-unit-space !important;
}
.pad-one-left,
.pad-one {
    padding-left: 1 * @one-unit-space !important;
}
.pad-one-right,
.pad-one {
    padding-right: 1 * @one-unit-space !important;
}
.pad-3q-left,
.pad-3q {
    padding-left: 0.75 * @one-unit-space!important;
}
.pad-3q-right,
.pad-3q {
    padding-right: 0.75 * @one-unit-space!important;
}
.pad-half-left,
.pad-half {
    padding-left: 0.5 * @one-unit-space!important;
}
.pad-half-right,
.pad-half {
    padding-right: 0.5 * @one-unit-space!important;
}
.pad-1q-left,
.pad-1q {
    padding-left: 0.25 * @one-unit-space!important;
}
.pad-1q-right,
.pad-1q {
    padding-right: 0.25 * @one-unit-space!important;
}
.pad-1e-left,
.pad-1e {
    padding-left: 0.125 * @one-unit-space!important;
}
.pad-1e-right,
.pad-1e {
    padding-right: 0.125 * @one-unit-space!important;
}
.nopad-left,
.nopad {
    padding-left: 0 !important;
}
.nopad-right,
.nopad {
    padding-right: 0 !important;
}
.xxs-plus({
    .pad-line-left-xxs,     .pad-line-xxs  { padding-left: 1.5*@one-unit-space !important; }
    .pad-line-right-xxs,    .pad-line-xxs  { padding-right:1.5*@one-unit-space !important; }
    .pad-one-left-xxs,      .pad-one-xxs   { padding-left: 1*@one-unit-space !important; }
    .pad-one-right-xxs,     .pad-one-xxs   { padding-right:1*@one-unit-space !important; }
});
.xs-plus({
    .pad-two-left-xs,       .pad-two-xs    { padding-left: 2*@one-unit-space !important; }
    .pad-two-right-xs,      .pad-two-xs    { padding-right:2*@one-unit-space !important; }
    .pad-line-left-xs,      .pad-line-xs   { padding-left: 1.5*@one-unit-space !important; }
    .pad-line-right-xs,     .pad-line-xs   { padding-right:1.5*@one-unit-space !important; }
    .pad-one-left-xs,       .pad-one-xs    { padding-left: 1*@one-unit-space !important; }
    .pad-one-right-xs,      .pad-one-xs    { padding-right:1*@one-unit-space !important; }
    .pad-half-left-xs,      .pad-half-xs   { padding-left: 0.5*@one-unit-space!important; }
    .pad-half-right-xs,     .pad-half-xs   { padding-right:0.5*@one-unit-space!important; }
    .pad-1q-left-xs,        .pad-1q-xs     { padding-left: 0.25*@one-unit-space!important; }
    .pad-1q-right-xs,       .pad-1q-xs     { padding-right:0.25*@one-unit-space!important; }
    .nopad-left-xs,         .nopad-xs      { padding-left: 0 !important; }
    .nopad-right-xs,        .nopad-xs      { padding-right:0 !important; }
});
.sm-plus({
    .pad-three-left-sm,     .pad-three-sm  { padding-left: 3*@one-unit-space !important; }
    .pad-three-right-sm,    .pad-three-sm  { padding-right:3*@one-unit-space !important; }
    .pad-two-left-sm,       .pad-two-sm    { padding-left: 2*@one-unit-space !important; }
    .pad-two-right-sm,      .pad-two-sm    { padding-right:2*@one-unit-space !important; }
    .pad-line-left-sm,      .pad-line-sm   { padding-left: 1.5*@one-unit-space !important; }
    .pad-line-right-sm,     .pad-line-sm   { padding-right:1.5*@one-unit-space !important; }
    .pad-one-left-sm,       .pad-one-sm    { padding-left: 1*@one-unit-space !important; }
    .pad-one-right-sm,      .pad-one-sm    { padding-right:1*@one-unit-space !important; }
    .pad-half-left-sm,      .pad-half-sm   { padding-left: 0.5*@one-unit-space!important; }
    .pad-half-right-sm,     .pad-half-sm   { padding-right:0.5*@one-unit-space!important; }
    .nopad-left-sm,         .nopad-sm      { padding-left: 0 !important; }
    .nopad-right-sm,        .nopad-sm      { padding-right:0 !important; }
});

.ngap-line-left,
.ngap-line {
    margin-left: -1.5 * @one-unit-space !important;
}
.ngap-line-right,
.ngap-line {
    margin-right: -1.5 * @one-unit-space !important;
}
.ngap-one-left,
.ngap-one {
    margin-left: -1 * @one-unit-space !important;
}
.ngap-one-right,
.ngap-one {
    margin-right: -1 * @one-unit-space !important;
}
.ngap-half-left,
.ngap-half {
    margin-left: -0.5 * @one-unit-space !important;
}
.ngap-half-right,
.ngap-half {
    margin-right: -0.5 * @one-unit-space !important;
}
.ngap-1q-left,
.ngap-1q {
    margin-left: -0.25 * @one-unit-space !important;
}
.ngap-1q-right,
.ngap-1q {
    margin-right: -0.25 * @one-unit-space !important;
}
.ngap-1e-left,
.ngap-1e {
    margin-left: -0.125 * @one-unit-space !important;
}
.ngap-1e-right,
.ngap-1e {
    margin-right: -0.125 * @one-unit-space !important;
}
.gap-line-left,
.gap-line {
    margin-left: 1.5 * @one-unit-space !important;
}
.gap-line-right,
.gap-line {
    margin-right: 1.5 * @one-unit-space !important;
}
.gap-one-left,
.gap-one {
    margin-left: 1 * @one-unit-space !important;
}
.gap-one-right,
.gap-one {
    margin-right: 1 * @one-unit-space !important;
}
.gap-half-left,
.gap-half {
    margin-left: 0.5 * @one-unit-space !important;
}
.gap-half-right,
.gap-half {
    margin-right: 0.5 * @one-unit-space !important;
}
.gap-1q-left,
.gap-1q {
    margin-left: 0.25 * @one-unit-space !important;
}
.gap-1q-right,
.gap-1q {
    margin-right: 0.25 * @one-unit-space !important;
}
.gap-1e-left,
.gap-1e {
    margin-left: 0.125 * @one-unit-space !important;
}
.gap-1e-right,
.gap-1e {
    margin-right: 0.125 * @one-unit-space !important;
}
.nogap-left,
.nogap {
    margin-left: 0 !important;
}
.nogap-right,
.nogap {
    margin-right: 0 !important;
}
.xs-plus({
    .ngap-1q-left-xs,       .ngap-1q-xs     { margin-left: -0.25*@one-unit-space !important; }
    .ngap-1q-right-xs,      .ngap-1q-xs     { margin-right:-0.25*@one-unit-space !important; }
    .nogap-left-xs,         .nogap-xs       { margin-left: 0 !important; }
    .nogap-right-xs,        .nogap-xs       { margin-right:0 !important; }
});
.sm-plus({
    .ngap-line-left-sm,     .ngap-line-sm   { margin-left: -1.5*@one-unit-space !important; }
    .ngap-line-right-sm,    .ngap-line-sm   { margin-right:-1.5*@one-unit-space !important; }
    .ngap-one-left-sm,      .ngap-one-sm    { margin-left: -1*@one-unit-space !important; }
    .ngap-one-right-sm,     .ngap-one-sm    { margin-right:-1*@one-unit-space !important; }
    .nogap-left-sm,         .nogap-sm       { margin-left: 0 !important; }
    .nogap-right-sm,        .nogap-sm       { margin-right:0 !important; }
});
.lg-plus({
    .nogap-left-lg,         .nogap-lg       { margin-left: 0 !important; }
    .nogap-right-lg,        .nogap-lg       { margin-right:0 !important; }
});

// Scroll spacer
.scroll-space-four-top {
    scroll-margin-top: 4 * @one-unit-space;
}
