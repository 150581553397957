/* ============= */
/* = Variables = */
/* ============= */

// Type

@title-stack: Helvetica, sans-serif;
@title-webfont-stack: IBM Plex Sans, @title-stack;
@title-leading: 1.3;

@prose-stack: Helvetica, sans-serif;
@prose-webfont-stack: Source Sans Pro, @prose-stack;
@prose-leading: 1.5;

// Colours

@brand-primary-blue: #2379b7;
@brand-primary-blue-dark: #2073ae;
// Split Complementary (RGB Mode) from https://www.sessions.edu/color-calculator/
@brand-primary-red: #b7232f;
@brand-primary-yellow: #ceb627;

@brand-background-dark: #000000;
@brand-background-light: #f2f2f2;
@brand-background-white: #ffffff;

@brand-type-dark: #4d434a;
@brand-type-light: #859399;
@brand-type-white: #ffffff;

// Layout breakpoints

@xxxs-min: 0em;
@xxs-min: (448em/16);
@xs-min: (700em/16);
@sm-min: (900em/16);
@md-min: (1188em/16);
@lg-min: (1480em/16);
@xl-min: (1920em/16);

@xxxs-max: (@xxs-min - 0.01em);
@xxs-max: (@xs-min - 0.01em);
@xs-max: (@sm-min - 0.01em);
@sm-max: (@md-min - 0.01em);
@md-max: (@lg-min - 0.01em);
@lg-max: (@xl-min - 0.01em);

@breakpoint_suffixes: ~'-xxs', ~'-xs', ~'-sm', ~'-md', ~'-lg', ~'-xl';
@breakpoint_widths: @xxs-min, @xs-min, @sm-min, @md-min, @lg-min, @xl-min;

// Function to recursively declare a set of rules
// at every breakpoint, starting with the default
// (no media query) breakpoint
.recurse-breakpoints-all(@ruleset) {
    .declare-breakpoint(@ruleset);
    .declare-breakpoint(@ruleset, @xxs-min,~'-xxs');
    .declare-breakpoint(@ruleset, @xs-min, ~'-xs');
    .declare-breakpoint(@ruleset, @sm-min, ~'-sm');
    .declare-breakpoint(@ruleset, @md-min, ~'-md');
    .declare-breakpoint(@ruleset, @lg-min, ~'-lg');
}
.recurse-breakpoints(@ruleset) {
    .declare-breakpoint(@ruleset);
    .declare-breakpoint(@ruleset, @xs-min, ~'-xs');
    .declare-breakpoint(@ruleset, @sm-min, ~'-sm');
    .declare-breakpoint(@ruleset, @lg-min, ~'-lg');
}
.declare-breakpoint(@ruleset, @suffix: ~'') {
    @ruleset();
}
.declare-breakpoint(@ruleset, @min-width, @suffix) {
    @media (min-width: @min-width) {
        @ruleset();
    }
}

// Recursilvely declare a rule set for an array of
// values <=> (min-width) breakpoints
// eg: ( { padding: @value }, (1rem, 2rem), (@xs-min, @md-min) )
.recurse-rule-breakpoints(@ruleset, @values, @breakpoints, @min-width: 0, @max-width: (@xl-min + 1rem)) {
    .recurse-rule-breakpoints-loop(@ruleset, @values, @breakpoints, length(@breakpoints));

    .recurse-rule-breakpoints-loop(@ruleset, @values, @breakpoints, @index)
        when
        (@index > 0) {
        .recurse-rule-breakpoints-loop(@ruleset, @values, @breakpoints, @index - 1);
        @value: extract(@values, @index);
        @breakpoint: extract(@breakpoints, @index);
        .declare-rule-breakpoint(@ruleset, @value, @breakpoint, @min-width, @max-width);
    }
    .declare-rule-breakpoint(@ruleset, @value, @breakpoint, @min-width, @max-width)
        when
        (@breakpoint = '')
        and
        (@min-width = 0) {
        @ruleset();
    }
    .declare-rule-breakpoint(@ruleset, @value, @breakpoint, @min-width, @max-width)
        when
        (default())
        and
        (@min-width =< @breakpoint)
        and
        (@max-width > @breakpoint) {
        @media (min-width: @breakpoint) {
            @ruleset();
        }
    }
}

// Responsive helpers
.xxs-plus(@rules) {
    @media (min-width: @xxs-min) {
        @rules();
    }
}
.xs-plus(@rules) {
    @media (min-width: @xs-min) {
        @rules();
    }
}
.sm-plus(@rules) {
    @media (min-width: @sm-min) {
        @rules();
    }
}
.md-plus(@rules) {
    @media (min-width: @md-min) {
        @rules();
    }
}
.lg-plus(@rules) {
    @media (min-width: @lg-min) {
        @rules();
    }
}
.xl-plus(@rules) {
    @media (min-width: @xl-min) {
        @rules();
    }
}

.xxxs-upto(@rules) {
    @media (max-width: @xxxs-max) {
        @rules();
    }
}
.xxs-upto(@rules) {
    @media (max-width: @xxs-max) {
        @rules();
    }
}
.xs-upto(@rules) {
    @media (max-width: @xs-max) {
        @rules();
    }
}
.sm-upto(@rules) {
    @media (max-width: @sm-max) {
        @rules();
    }
}
.md-upto(@rules) {
    @media (max-width: @md-max) {
        @rules();
    }
}

// Font serving on Retina devices
.retina-font-weight(@font-weight; @important:~'') {
    @media (-webkit-min-device-pixel-ratio: 1.5),
        (min--moz-device-pixel-ratio: 1.5),
        (min-resolution: 1.5dppx),
        (min-resolution: 144dpi) {
        & {
            font-weight: @font-weight @important;
        }
    }
}
