/* ========== */
/* = Layout = */
/* ========== */

// Primary Grid
.recurse-breakpoints-all({
    .col-grid@{suffix}   {
        display: flex; margin-left: -@one-unit-space;
        > .col { min-width: 0; padding-left: @one-unit-space; }
        
        //            margin-left: -4.17vw; > .col { padding-left: 4.17vw; }
        // .sm-plus({ margin-left: -3.67vw; > .col { padding-left: 3.67vw; } });
        // .md-plus({ margin-left: -2.75vw; > .col { padding-left: 2.75vw; } });
        
        &.grid-gutter {
            margin-left:   -@one-unit-space * 0.75;
            margin-bottom: -@one-unit-space * 1;
            > .col {
                padding-left:   @one-unit-space * 0.75;
                padding-bottom: @one-unit-space * 1;
            }
        }
        &.grid-gutter-half {
            margin-left:   -@one-unit-space * 0.5;
            margin-bottom: -@one-unit-space * 0.5;
            > .col {
                padding-left:   @one-unit-space * 0.5;
                padding-bottom: @one-unit-space * 0.5;
            }
        }
        &.no-gutter { margin-left: 0; > .col { padding-left: 0; } }
    }
    
    .col.cg-7eighth@{suffix}{ flex: 0 0 percentage(7/8); }
    .col.cg-5eighth@{suffix}{ flex: 0 0 percentage(5/8); }
    .col.cg-3eighth@{suffix}{ flex: 0 0 percentage(3/8); }
    .col.cg-eighth@{suffix} { flex: 0 0 percentage(1/8); }
    // .col.pad-eighth@{suffix}{ margin-left: percentage(1/8); }
    //
    // .col.cg-5seventh@{suffix}   { flex: 0 0 percentage(5/7); }
    // .col.cg-4seventh@{suffix}   { flex: 0 0 percentage(4/7); }
    // .col.cg-3seventh@{suffix}   { flex: 0 0 percentage(3/7); }
    // .col.cg-2seventh@{suffix}   { flex: 0 0 percentage(2/7); }
    // .col.cg-seventh@{suffix}    { flex: 0 0 percentage(1/7); }
    // .col.pad-2seventh@{suffix}{ margin-left: percentage(2/7); }
    // .col.pad-seventh@{suffix} { margin-left: percentage(1/7); }
    //
    // .col.cg-5sixth@{suffix} { flex: 0 0 percentage(5/6); }
    // .col.cg-sixth@{suffix}  { flex: 0 0 percentage(1/6); }
    // .col.pad-sixth@{suffix} { margin-left: percentage(1/6); }
    //
    // .col.cg-4fifth@{suffix} { flex: 0 0 percentage(4/5); }
    // .col.cg-fifth@{suffix}  { flex: 0 0 percentage(1/5); }
    // .col.pad-fifth@{suffix} { margin-left: percentage(1/5); }
    //
    // .col.cg-3fourth@{suffix}{ flex: 0 0 percentage(3/4); }
    // .col.cg-fourth@{suffix} { flex: 0 0 percentage(1/4); }
    // .col.pad-fourth@{suffix} { margin-left: percentage(1/4); }
    
    .col.cpad-2fifth@{suffix}{margin-left: percentage(2/5); }
    .col.cpad-fifth@{suffix}{ margin-left: percentage(1/5); }
    .col.rpad-fifth@{suffix}{ margin-right:percentage(1/5); }
    .col.cpad-third@{suffix}{ margin-left: percentage(1/3); }
    .col.nocpad@{suffix}    { margin-left: 0; }
    
    .col.cg-sixth@{suffix}  { flex-basis: percentage(1/6); }
    .col.cg-fifth@{suffix}  { flex-basis: percentage(1/5); }
    .col.cg-third@{suffix}  { flex-basis: percentage(2/6); }
    .col.cg-fourth@{suffix} { flex-basis: percentage(1/4); }
    .col.cg-half@{suffix}   { flex-basis: percentage(3/6); }
    .col.cg-2fifth@{suffix} { flex-basis: percentage(2/5); }
    .col.cg-2third@{suffix} { flex-basis: percentage(4/6); }
    .col.cg-3fifth@{suffix} { flex-basis: percentage(3/5); }
    .col.cg-3fourth@{suffix}{ flex-basis: percentage(3/4); }
    .col.cg-4fifth@{suffix} { flex-basis: percentage(4/5); }
    .col.cg-5sixth@{suffix} { flex-basis: percentage(5/6); }
    .col.cg-full@{suffix}   { flex-basis: percentage(6/6); }
    .col.cg-zero@{suffix}   { flex-basis: 0; }
    
    .nogrid@{suffix} {
        display: block;
        margin-left: 0;
        .col { min-width: auto; padding-left: 0; }
    }
    
    .col-grid {
        &-middle@{suffix}   { align-items: center; }
        &-bottom@{suffix}   { align-items: flex-end; }
        &-ends@{suffix}     { justify-content: space-between; }
        &-centered@{suffix} { justify-content: space-around; }
        &-huddled@{suffix}  { justify-content: center; }
        &-left@{suffix}     { justify-content: flex-start; }
        &-right@{suffix}    { justify-content: flex-end; }
        &-vertical@{suffix} { flex-direction: column; }
        &-regular@{suffix}  { flex-direction: row; }
        &-wrap@{suffix}     { flex-wrap: wrap; }
        &-nowrap@{suffix}   { flex-wrap: nowrap; }
        &-nogrow@{suffix} > .col { flex-grow: 0; }
    }
});
.col.cg-gallery {
    flex: 1;
    min-width: 9rem;
    .xxs-plus({ min-width:  9rem; });
    .xs-plus({  min-width: 12rem; });
    .sm-plus({  min-width: 15rem; });
}

.grid-overlay {
    width: 100%;
    position: fixed;
    z-index: 10000;
    display: flex;
    opacity: 0.1;
    li {
        height: 100vh;
        color: transparent;
        .xs-upto({ &:nth-child(n+12) { display: none } });
        .sm-upto({ &:nth-child(n+14) { display: none } });
    }

    .gutter {
        flex-basis: percentage(25/1400);
        background: transparent;
    }
    .column {
        flex-basis: percentage(260/1400);
        background: silver;
    }

    // .xxs-plus({.gutter { flex-basis: 4.17%; } .column { flex-basis: 1% * (100-(4.17*6))/5; } });
    // .sm-plus({ .gutter { flex-basis: 3.67%; } .column { flex-basis: 1% * (100-(3.67*7))/6; } });
    // .md-plus({ .gutter { flex-basis: 2.75%; } .column { flex-basis: 1% * (100-(2.75*9))/8; } });
}
