/* ==========================================================================
   Boilerplate Helper classes
   ========================================================================== */

/* Prevent callout */
.nocallout {
    -webkit-touch-callout: none;
}

.pressed {
    background-color: rgba(0, 0, 0, 0.7);
}

/* A hack for HTML5 contenteditable attribute on mobile */
textarea[contenteditable] {
    -webkit-appearance: none;
}

/* A workaround for S60 3.x and 5.0 devices which do not animated gif images if
   they have been set as display: none */
.gifhidden {
    position: absolute;
    left: -100%;
}

/* Image replacement */
.has-js .js-ir,
.ir {
    display: block;
    border: 0;
    text-indent: -999em;
    overflow: hidden;
    background-color: transparent;
    background-repeat: no-repeat;
    text-align: left;
    direction: ltr;
}
.has-js .js-ir br,
.ir br {
    display: none;
}

.ir:before {
    content: '';
    display: block;
    width: 0;
    height: 150%;
}

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden {
    display: none !important;
    visibility: hidden;
}

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* Extends the .visuallyhidden class to allow the element to be focusable: h5bp.com/p */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/* Hide visually and from screenreaders, but maintain layout */
.invisible {
    visibility: hidden;
}

.clearfix:after {
    content: '';
    display: block;
    clear: both;
}
